<template>
  <div>
  <b-collapse :visible="cardIsVisible" appear>
    <div class="pt-1"></div>
    <div class="custom-run-card pb-1 ">
      <div align="right">
        <b-button class=" close-card-button" variant="none" @click="closeThisCard()">
          <b-icon icon="x" variant="secondary" scale="1"/>
        </b-button>
      </div>

      <h6 class="d-flex justify-content-between">
        <span>{{ getRunIdentifier() }}</span
        >
        <div>
          <span class="btn-run-card mt-0" @click="openTab()">{{$t('terminal_tests.terms.view_details')}}</span>
        </div>
      </h6>
      <div class="text-secondary">
        <div class="d-inline-block" v-if="run.origin.receiver">
          <p class="m-0 d-inline-block mr-1" v-if="run.origin.receiver">
            {{$t('terminal_tests.terms.receiver_run_list')}} <span class="text-reset-color">{{ run.origin.receiver }}</span>
          </p>
          |
        </div>
        <div class="d-inline-block" v-if="run.origin.service">
          <p class="m-0 d-inline-block ml-1 mr-1" v-if="run.origin.service">
            {{$t('terminal_tests.terms.sender_run_list')}} {{ run.origin.service }}
          </p>  
          |
        </div>
        <div class="d-inline-block" v-if="run.origin.event">
          <p class="m-0 d-inline-block mr-1">
            {{$t('terminal_tests.terms.event_run_list')}} {{ run.origin.event }}
          </p>
          |
        </div>
        <div class="d-inline-block" v-if="run.webhook">
          <p class="m-0 d-inline-block mr-1">
            {{$t('terminal_tests.terms.webhook_run_list')}} {{ run.webhook.identifier }}
          </p>
          |
        </div>
        <p class="m-0 d-inline-block ml-1">
          {{$t('terminal_tests.terms.total_time')}} {{ (run.time / (1000 * 1000)).toLocaleString() }}s
        </p>
      </div>
      <!-- <p class="m-0">{{$t('terminal_tests.terms.executed_at')}} {{ formatDate(run.created_at) }}</p> -->
      <div>
        <span class="btn-run-card" @click="toggleResponse">{{
          $t(
            responseShown
              ? "terminal_tests.terms.hide_response"
              : "terminal_tests.terms.show_response"
          )
        }}</span>
        <b-collapse :visible="responseShown">
          <div>
            <json-viewer
              v-if="run.json_return.info.RETURN && run.json_return.info.RETURN.BODY"
              class="rounded mt-1 bg-darker"
              :value="run.json_return.info.RETURN.BODY"
              :expand-depth="10"
              expanded

              theme="custom-jv-dark"
              copyable
              sort
            />
            <p v-else>{{$t('terminal_tests.terms.no_response')}}</p>
          </div>
        </b-collapse>
      </div>
    </div>
  </b-collapse>
  </div>
</template>

<script>
import { BCollapse, BButton } from "bootstrap-vue";
import JsonViewer from "vue-json-viewer";
import Run from "./class/run";
import moment from "moment";

export default {
  components: {
    JsonViewer,
    BCollapse,
    BButton,
  },
  props: {
    run: {
      type: Run,
      required: true,
    },
  },
  data() {
    return {
      responseShown: true,
      cardIsVisible: true,
    };
  },
  methods: {
    toggleResponse() {
      this.responseShown = !this.responseShown;
    },
    getRunIdentifier() {
      return `RUN # ${this.run.index + 1}`;
    },
    openTab() {
      
      this.$emit("addTab", {
        key: `run.${this.run.index}.tab.details`,
        name: `${this.getRunIdentifier()} - Details`,
        component: "RunLogInfo",
        closable: true,
        createOpenned: true,
        properties: {
          run: this.run,
          isTerminal: true,
        },
      });
    },
    formatDate(value) {
      if (!value) return null
      let date = new Date(value)
      return moment(date).format(this.$t('scheduler.view.moment_date_format'));
    },
    closeThisCard(){
      this.cardIsVisible = false
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.bg-darker{
  background-color: transparentize($black, 0.05);
}
.custom-run-card {
  font-family: monospace;
  //border-bottom: dashed 1px rgba(255, 255, 255, 0.5);
  position: relative;
  background-color: transparentize($secondary, 0.8);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid $dark;
  margin-bottom: 10px;
}

.btn-run-card {
  display: inline-block;
  margin-top: 5px;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
  transition: all 0.1s ease-in;

  &:hover {
    opacity: 0.7;
  }
}

.close-card-button{
  opacity: 0.5;
  padding: 5px;
  position: absolute;
  top: -28px;
  right: 0px;
  background-color: transparent;
  border-radius: 7px 7px 0px 0px;
}

</style>