<template>
  <div>
    <div class="middleware-block-container">
      <div class="d-flex justify-content-between align-items-end">
        <div class="d-flex align-items-center">

          <div 
            v-if="type && log" tabindex="0"
            class="d-flex align-items-center position-relative middleware-block" 
            :class="agentLogs && hasAgentLogs ? 'cursor-pointer': ''" 
            @click="agentsVisible = !agentsVisible"
          >
            <div class="middleware-icon-container">
              <b-icon :icon="type.icon" class="m-0 p-0" scale="1"/>
            </div>

            <div class="d-flex flex-wrap align-items-center w-100">
              
              <div class=" d-flex justify-content-between align-items-start w-100">

                <div>
                  <span class="font-weight-bolder middleware-log-name text-truncate">{{ log.name }}</span>
                </div>
                <div class="small bg-black rounded">
                  <template v-if="log.error && !type.is_end_block">
                    <b-badge v-if="log.is_fatal" variant="light-danger" class="shadow">CRITICAL ERROR</b-badge>
                    <b-badge v-else variant="light-warning" class="shadow">ERROR</b-badge>
                  </template>
                </div>
                
              </div>
              
              <div class="w-100 d-flex align-items-end justify-content-between">
                <div class="small text-dark font-weight-bold">{{ $t(`middleware.list.${type.i18key}.name`) }}</div>

                <b-button variant="none" class="small hide-show-mid-btn" v-if="hasChildren" @click.stop="$emit('toggleTimeline')">
                  <template v-if="timelineOpened">hide</template>
                  <template v-else>show</template>
                </b-button>

              </div>
            </div>
          </div>

          <div class="ml-1">
            <b-button v-if="agentLogs && hasAgentLogs" variant="none" class="toggle-agents-btn" :class="{'upside-down': agentsVisible}" @click="agentsVisible = !agentsVisible">
              <b-icon icon="chevron-down" scale="0.7" class="m-0"/>
            </b-button>
          </div>

        </div>

        <div class="d-flex align-items-end">
          
          <div class="text-dark w-fit-content small mx-1">
            Time Executing: 
            <b-icon icon="stopwatch" scale="1.2" variant="info" class="mx-25"/>
            <span class="text-info font-weight-bold" v-b-tooltip.hover :title="`${log.elapsed_time.toLocaleString()}  nanoseconds`">
              {{ parseFloat(log.elapsed_time/( 10**9)).toFixed(1) }}s
            </span>
          </div>
          
          <b-button class="py-25 px-50 mr-1" variant="outline-light" @click="visible.middlewareLogs = !visible.middlewareLogs" v-if="middlewareLogs && middlewareLogs.length > 0"> 
            <template v-if="visible.middlewareLogs">Hide Middleware Log</template>
            <template v-else>Show Middleware Log</template>
          </b-button>

        </div>

      </div>

      <!-- <div v-if="log.message" class="bg-light-warning rounded p-50">
        {{ log.message }}
      </div> -->

      <div v-if="middlewareLogs && middlewareLogs.length > 0">
        <b-collapse :visible="visible.middlewareLogs" class="middleware-log-data">
          <log-data-visualizer :data="middlewareLogs"/>
        </b-collapse>
      </div>
    </div>
    <b-collapse :visible="agentsVisible">
      <div v-if="agentLogs && hasAgentLogs" class="angent-logs-container">
        <div>
          <!-- <h5>Agent Logs:</h5> -->
        </div>

        <div v-for="(block, index) in Object.keys(agentLogs)" :key="`block-${uuid}-${block}`">
          <template v-if="agentLogs[block] && agentLogs[block].length">
            <div>
              <h4 class="m-0">
              <b-badge :variant="'light-'+blockDic[block].variant" class="mb-50">
                {{ blockDic[block].label }}
              </b-badge></h4>
              <div>
                <log-data-visualizer :data="agentLogs[block]" :agentBlock="block"/>
              </div>
            </div>
          </template>
        </div>

      </div>
    </b-collapse>

 </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BIcon,
  BBadge,
  BButton,
  BTooltip,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
import Middlewares from "@/custom/class/Enum/Middlewares.js"
import LogDataVisualizer from "@/layouts/components/Terminal/TerminalTabs/Run/Refactored/LogDataVisualizer.vue"
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {  
    BCard,
    BCollapse,
    BIcon,
    BBadge,
    BButton,
    BTooltip,
    VBTooltip,
    LogDataVisualizer,
  },
  
  directives: {
    "b-tooltip": VBTooltip,
  },
  
  data() {
    return {
      uuid: uuidv4(),
      type: undefined,
      middlewareLogs: undefined,
      agentLogs: undefined,

      agentsVisible: false,

      hasAgentLogs: false,

      visible:{
        middlewareLogs: false,
        agents: false,
      },

      blockDic:{
        try: {label: "Try", variant: "info"},
        then: {label: "Then", variant: "success"},
        catch: {label: "Catch", variant: "danger"},
      }
    }
  },
  props: {
    log: {
      type: [Object, Array],
      default: undefined 
    },
    hasChildren:{
      type: Boolean,
      default: false,
    },
    timelineOpened:{
      type: Boolean,
      default: true,
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init(){
      this.separateMiddlewareLogs()
      this.type = this.middlewareType(this.log.type)
    },
    separateMiddlewareLogs(){
      const log = structuredClone(this.log.logs)

      let midLog = []
      let agentLog = {
        try: [],
        then: [],
        catch: [],
      }
      
      Object.keys(log).forEach(agentBlock => {
        if (log[agentBlock]){
          log[agentBlock].forEach(l => {
            if (l.type == 0){
              midLog.push(l)
            } else {
              agentLog[agentBlock].push(l)
              this.hasAgentLogs = true;
            }
          });
        }
      });
      
      if (this.log.message){
        midLog.push({message: this.log.message , error:true})
      }

      this.middlewareLogs = midLog
      this.agentLogs = agentLog

    },
    formataDate(date) {
      date = new Date(date)
      return new moment(date).calendar()
    },
    middlewareType(enumId){
      return new Middlewares().items.find(el => el.id == enumId)
    },

  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.hide-show-mid-btn{
  cursor: pointer;
  background-color: transparentize($black, 0.1);
  border-radius: 15px 2px 2px 2px;
  padding: 2px 12px 3px 16px;
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: 500;
  outline: 1px solid $middleware-bg;
  transition: background 0.15s;
  color: $light;
  &:active{
    background-color: transparentize($black, 0.4);
  }
}

.toggle-agents-btn{
  border-radius: 100px;
  background-color: $middleware-bg;
  padding: 1px;
  transition: transform 0.2s , outline 0.05s;
  color: $light;
  outline: 2px solid $dark;
  &:focus, &:hover{
    outline: 2px solid transparentize($light, 0.5);
  }
  &:active{
    outline: 2px solid $light !important;
  }
  &.upside-down{
    transform: rotate(180deg);
  }
}

.middleware-log-data{
  border: 2px solid $middleware-bg;
  border-radius: 0 5px 5px 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
}

.angent-logs-container{
  margin-right: 5px;
  margin-left: 50px;
  border-left: 4px solid $middleware-bg;
  background-color: $agent-log-color;
  padding: 8px;
  border-radius: 0 5px 5px 0px;
}


.middleware-block-container{
  border-radius: 5px;
  margin-left: 15px;
  color: $light;

  .middleware-block{
    background-color: $middleware-bg;
    border-radius: 5px;
    min-width: 420px;
    max-width: 420px;
    width: fit-content;
    padding: 3px;
  }

  .middleware-log-name{
    $middleware-name-length: 255px;
    max-width:  $middleware-name-length;
    width:  $middleware-name-length;
    min-width:  $middleware-name-length;
    display: block;
    width: fit-content;
  }
}

.middleware-icon-container{
  font-size: 18px;
  background-color: $icon-bg-color;
  display: inline-block;
  padding: 4px 8px;
  
  border-radius: 4px;
  margin: 0  5px 0 3px;
}

.toggle-timeline-btn{
  position: absolute;
  right: 5px;
  bottom: 0;
  transform: translateY(100%);
  cursor: pointer;

  font-size: 12px;
  text-align: center;
  border-radius: 0 0 5px 5px;
  background-color: transparentize($middleware-bg, .85);
  width: 100px;
  color: $secondary;
  border: 2px solid $middleware-bg;
  border-top: none;
  font-weight: bold;
}
</style>