<template>
  <div class="runs-container">
    <b-button class="clear-runs-btn small mt-1 py-0 px-25" variant="none" @click="clearRuns()">Clear Runs</b-button>
    <h3 class="text-uppercase text-center text-muted">
      {{$t("terminal_tests.terms.runs_list")}}
    </h3>
    
    <vue-perfect-scrollbar v-if="hasRun" class="controlled-height-log-container px-1">
      <div  v-for="(run, idx) in runs" :key="`run-list-n-${run.index}`">
        <run-card v-if="!run.error" @addTab="addTab" :key="`run-list-n-${run.index}`" :ref="`run-list-n-${idx}`" :run="run" />
        <error-run-card v-else :key="'error-run-'+ run.runId" class="error-run-container" :ref="`run-list-n-${idx}`" :run="run"/>
      </div>
    </vue-perfect-scrollbar>

    <div v-else>
      <p class="text-center text-uppercase mb-0">{{$t("terminal_tests.terms.no_runs")}}</p>
    </div>
  </div>
</template>

<script>
import Run from "./class/run";
import RunCard from "@/layouts/components/Terminal/TerminalTabs/Run/RunCard.vue";
import { BButton } from "bootstrap-vue";
import ErrorRunCard from '@/layouts/components/Terminal/TerminalTabs/Run/ErrorRunCard.vue'
import { v4 as uuidv4 } from 'uuid'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    RunCard,
    BButton,
    ErrorRunCard,
    VuePerfectScrollbar
  }, 
  data() {
    return {
      runs: [],
      hasRun: false,
    }
  },
  computed: {
    transmissionID() {
      return this.$route.params.transmissionID;
    },
  },
  methods: {
    addRun(data) {
      let item = data.data
      const index = this.runs.length;

      const run = new Run({
        index: index,
        transmission_id: this.transmissionID,
        token: item.token,
        origin: item.origin,
        success: item.success,
        level: item.level,
        message: item.message,
        event: item.origin.event || undefined,
        sender: item.origin.service || undefined,
        receiver: item.origin.receiver || undefined,
        webhook: item.origin.webhook || undefined,
        scheduler: item.origin.scheduler || undefined,
        json_return: {
          logs: {
            '$IN': item.data['$IN'],
            '$OUT': item.data['$OUT'],
          },
          info: item.data.info,
        },
        created_at: item.created_at,
        time: item.elapsed_time,
        script_id: item.script_id,
      });

      this.hasRun = true;
      this.runs.unshift(run);
    },
    addError(item, timeRunning){
      let errorRun = {
        error:true,
        data: item,
        runId: uuidv4(),
        time: timeRunning
      }
      
      this.runs.unshift(errorRun)
      this.hasRun = true;
    },
    addTab(tab) {
      this.$emit("addTab", tab);
    },
    clearRuns(){
      let idx = 0
      this.runs.forEach(run => {
        this.$refs[`run-list-n-${idx}`][0].closeThisCard();
        idx = idx + 1
      });
    }
  }
}
</script>

<style lang="scss" scoped>

.clear-runs-btn{
  position: absolute;
  right: 30px;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.runs-container {
    font-family: monospace;
    background: rgb(5, 5, 5);
    padding: 15px 0;
    border-radius: 5px;
    color: rgb(180, 183, 189) !important;

    h3 {
      color: rgb(180, 183, 189) !important;
      padding-bottom: 10px;
      margin-bottom: 15px;;
      border-bottom: dashed 1px rgba(255, 255, 255, 0.5);
    }
  }

  .controlled-height-log-container{
    max-height: 51vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>