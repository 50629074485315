<template>
  <div>
    <div class="jsoneditor-vue" :style="`height: ${height}${proportionType}`"></div>
  </div>
</template>

<script>
import JsonEditor from "jsoneditor";
import "brace/theme/monokai";

export default {
  props: {
    value: [String, Number, Object, Array],
    expandedOnStart: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "code",
    },
    modes: {
      type: Array,
      default: () => { return ["tree", "code", "form", "view", "text", "preview" ]; }
    },
    height: {
      type: Number,
      default: 250
    },
    proportionType: {
      type: String,
      default: "px"
    }
  },
  computed: {
    jsonValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  },
  data() {
    return {
      editor: null,
      error: false,
      json: this.value,
      internalChange: false,
      expandedModes: ["tree", "view", "form"],
    };
  },
  mounted() {
    let self = this;
    let options = {
      theme: "ace/theme/monokai",
      mode: this.mode,
      modes: this.modes, // allowed modes
      enableTransform: false,
      enableSort: false,
      onChange() {
        try {
          let json = self.editor.get();
          self.jsonValue = json;
          self.error = false;
          self.$emit("json-change", json);
          self.internalChange = true;
          self.$emit("input", json);
          self.$nextTick(function () {
            self.internalChange = false;
          });
        } catch (e) {
          self.error = true;
          self.$emit("has-error", e);
        }
      },
      onModeChange() {
        self.expandAll();
      },
      onValidationError(e) {
        self.$emit("invalidJson", e);
      }
    };

    this.editor = new JsonEditor(
      this.$el.querySelector(".jsoneditor-vue"),
      options,
      this.jsonValue
    );
  },
  watch: {
    jsonValue: {
      immediate: true,
      async handler(val) {
        if (!this.internalChange) {
          await this.setEditor(val);
          this.error = false;
          this.expandAll();
        }
      },
      deep: true,
    },
  },
  methods: {
    expandAll() {
      if (
        this.expandedOnStart &&
        this.expandedModes.includes(this.editor.getMode())
      ) {
        this.editor.expandAll();
      }
    },
    onSave() {
      this.$emit("json-save", this.jsonValue);
    },
    async setEditor(value) {
      if (this.editor) this.editor.set(value);
    },
  },
};
</script>

<style lang="scss">
.ace-jsoneditor *,
textarea.jsoneditor-text * {
  font-family: dejavu sans mono, droid sans mono, consolas, monaco,
    lucida console, courier new, courier, monospace, sans-serif !important;
}

.jsoneditor {
  border: none !important;

  .jsoneditor-menu {
    &:not(ul) {
      background-color: #23a25c !important;
      border-bottom: 1px solid #23a25c !important;
      border-radius: 5px 5px 0px 0px !important;
    }

    .jsoneditor-selected {
      background-color: #23a25c !important;
    }
  }

  .ace_editor {
    border: solid 1px #202020;
    border-bottom: none;
    border-top: none;
  }
}
</style>
