<template>
  <div v-if="$can('create')">
    <b-button variant="relief-success" @click="$refs['modal-compile-script'].show()">
      {{ $t("compiled.buttons.add") }}
    </b-button>
    
    <b-modal
      centered
      ref="modal-compile-script"
      ok-variant="favorite"
      :cancelTitle="$t('common.modal.no')"
      title="Confirm Compilation"
      @hidden="newScriptName=''"
      @hide="((e)=>{ if (isModalLoading) e.preventDefault()})"
      @ok="compileScript"
      :ok-disabled="isModalLoading"
    >
    <div @keydown.enter="compileScript()">
      <p>{{$t("compiled.modal.new.message")}}</p>
      <label>Script Name</label>
      <b-form-input :disabled="isModalLoading" @input="nameInputState=undefined" v-model="newScriptName" :state="nameInputState" autofocus></b-form-input>
     </div>
     <template #modal-ok>
      <div>
        <span v-if="!isModalLoading">{{$t('common.modal.yes')}}</span>
        <b-spinner small v-else/>
      </div>
     </template>
    </b-modal>
  </div>
</template>

<script>
import { 
  BButton,
  BModal,
  BFormInput,
  BSpinner
 } from "bootstrap-vue";
import { makeToast } from "@/layouts/components/Popups.js";

export default {
  components: {
    BButton,
    BModal,
    BFormInput,
    BSpinner
  },
  data() {
    return {
      isModalLoading: false,
      newScriptName: '',
      nameInputState: undefined
    }
  },
  methods: {
    handleCompile() {
      this.$bvModal
        .msgBoxConfirm(this.$t("compiled.modal.new.message"), {
          title: this.$t("compiled.modal.new.title"),
          size: "sm",
          okVariant: "warning",
          okTitle: this.$t("common.modal.yes"),
          cancelTitle: this.$t("common.modal.no"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("compileScript", this.$route.params.transmissionID)
              .then(() => {
                makeToast({
                  title: this.$t("environments.toast.compile.success.title"),
                  text: this.$t("environments.toast.compile.success.message"),
                  variant: "success",
                  icon: "CheckIcon",
                });              
              })
              .catch((err) => {
                console.error(err)
                makeToast({
                  title: this.$t("environments.toast.compile.error.title"),
                  text: this.$t("environments.toast.compile.error.message"),
                  variant: "danger",
                  icon: "XIcon",
                }); 
              })
          }
        });
    },
    compileScript(ev){
      if (!this.newScriptName.length){
        ev.preventDefault()
        this.nameInputState = false
        return
      }
      let name = this.newScriptName
      this.isModalLoading = true
      this.$store
        .dispatch("compileScript", {transmissionID: this.$route.params.transmissionID, scriptName: name})
        .then((resp) => {

          if (this.$route.name != 'scripts'){
            this.$router.push({ name: 'scripts'});
          }

          makeToast({
            title: this.$t("environments.toast.compile.success.title"),
            text: this.$t("environments.toast.compile.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });              
          this.isModalLoading = false

          this.$nextTick(()=>{
            this.$refs['modal-compile-script'].hide()
          })

        })
        .catch((err)=>{
          console.error(err)
          makeToast({
            title: this.$t("environments.toast.compile.error.title"),
            text: this.$t("environments.toast.compile.error.message"),
            variant: "danger",
            icon: "CheckIcon",
          });    
          this.isModalLoading = false;

          if (this.$route.name != 'scripts'){
            this.$router.push({ name: 'scripts'});
          }

        })
        
    }
  },
};
</script>

<style></style>
