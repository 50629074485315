<template>
  <json-viewer
    :value="parsedData ? parsedData : data"
    :expand-depth="15"
    :copyable="copyable"
    :show-array-index="false"
    :show-double-quotes="true"
    theme="custom-jv-dark"
    class="bg-pre custom-pre-component"
    :style="`
      max-height: ${maxHeight};
      min-height: ${minHeight};
    `"
  />
</template>

<script>
import JsonViewer from "vue-json-viewer";
import {copyText} from "@/custom/class/FunctionClasses/CommonToasts.js";

import {
  BButton
} from "bootstrap-vue";
export default {
  components: {
    BButton,
    JsonViewer
  },
  props: {
    data: {
      type: [Array, Object, Number, String, Boolean,],
      default: 'undefined',
    },
    maxHeight:{
      type: String,
      default: '300px'
    },
    minHeight:{
      type: String,
      default: '80px'
    },
    copyable:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      parsedData: undefined
    }
  },
  mounted () {
  },
  methods: {
    copyDataToClipboard(){
      let data = structuredClone(this.data)
      if (typeof data != 'string'){
        data = JSON.stringify(data)
      }
      copyText(data)
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/json-viewer-themes.scss';
</style>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.bg-pre{
  background-color: $pre-bg;
}
div.custom-pre-container{
  border-radius: 5px;
  color: var(--light);
  position: relative;
}

.custom-pre-component{
  overflow: auto;
  max-width: 100%;
  background-color: $pre-bg;
  border-radius: inherit;
}

.pre-copy-btn{
  position: absolute;
  right: 10px;
  top: 0;
  padding: 10px 5px;
  font-size: 13px;
}
</style>