<template>
  <div class="card w-100">
    <div v-if="transmission && !fetchingTransmission">
      <div class="pt-50">
        <b-row class="w-100 justify-content-between">
          <b-col class="mb-50 mt-50 ml-2">
            <span class="small text-secondaryfont-weight">{{ $t("common.terms.transmission") }}</span>
            <h2 class="mt-25">{{ transmission.name }}</h2>
          </b-col>
          <b-col class="d-flex justify-content-end mt-1">
            <helper
              class="d-inline-block mt-50"
              :title="$t('transmission.nav.helper.environment_selection.title')"
              variant="info"
              size="lg"
              :innerHTML="
                $t('transmission.nav.helper.environment_selection.innerHTML')
              "
            />
            <div class="d-inline-block mr-2 env-selection">
              <b-form-group>
                <v-select
                  class="w-100"
                  v-model="currentEnv"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  value="id"
                  :options="options"
                  :clearable="false"
                />
              </b-form-group>
            </div>
            <div class="d-inline-block">
              <compile-button />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mb-1 mt-50">
        <b-row class="w-100 justify-content-start ml-2 mb-50">
          <div v-for="(button, key) in buttons" :key="`btn-tg-nav-${key}`">
            <span v-if="button.items" @mouseover="openDropdown('dropdown-nav'+key)" @mouseleave="delayCloseDropdown('dropdown-nav'+key)">
              <b-dropdown
                class="mr-1"
                :id="`dropdown-tg-nav-${key}`"
                :text="button.text"
                :variant="button.variant"
                :ref="'dropdown-nav'+key"
              >
                <b-dropdown-item
                  v-for="(data, dp_key) in button.items"
                  :to="data.action"
                  :key="`btn-tg-nav-${dp_key}`"
                >
                  <feather-icon
                    class="mr-50"
                    v-if="data.icon"
                    :icon="data.icon"
                  />
                  {{ data.text }}
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <b-button
              
              v-else
              class="mr-2"
              :variant="button.variant"
              :to="button.action"
            >
              <feather-icon v-if="button.icon" :icon="button.icon" />
              {{ button.text }}
            </b-button>
          </div>
        </b-row>
      </div>
    </div>
    <div v-else>
      <div class="card-header">
        <b-skeleton width="100%" height="50px"></b-skeleton>
        <b-skeleton width="100%" height="50px"></b-skeleton>
      </div>
    </div>
  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSkeleton,
  BDropdown,
  BIcon,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CompileButton from "./CompileButton.vue";
import Helper from "@/layouts/components/Helper.vue";
import EventBus from "@/custom/eventBus";
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BAvatar,
    BButton,
    BSkeleton,
    vSelect,
    CompileButton,
    Helper,
    BIcon,
  },
  data() {
    return {
      options: [],
      currentEnv: undefined,
      buttons: [],
      transmissions: null,
      fetchingTransmission: false,
      dropdownTimer: undefined
    };
  },
  computed: {
    transmissionID() {
      return this.$route.params.transmissionID
    },
    transmission() {
      return this.getSelectedTransmissionGroup;
    },
    ...mapGetters(['getSelectedTransmissionGroup'])
  },
  mounted() {
    this.init();

    EventBus.$on("reloadEnvs", () => {
      this.fetchAllEnvironments();
    });
  },
  beforeDestroy() {
    EventBus.$off("reloadEnvs");
  },
  watch: {
    currentEnv() {
      const transmissionId = this.transmissionID
      localStorage.setItem(
        `currentEnv-${transmissionId}`,
        JSON.stringify(this.currentEnv)
      );
    },
  },
  methods: {
    init() {
      if (!this.getSelectedTransmissionGroup || this.getSelectedTransmissionGroup.id != this.transmissionID) {
        this.fetchTransmissionGroupByOrganization()
      }
      this.fetchAllEnvironments();
      this.buildButtons();
    },
    fetchTransmissionGroupByOrganization() {
      if(this.$route.meta.loadTransmissionGroup){
        this.fetchingTransmission = true;
        this.$store.dispatch('getTransmissionGroupById', { transmissionID: this.transmissionID } ).then(() => {
          this.fetchingTransmission = false;
        })
        .catch((error) => {
          makeToast({
            title: this.$t("integrator.toast.confirm_install.error.title"),
            text: this.$t("integrator.toast.confirm_install.error.message"),
            variant: "danger",
            icon: "CheckIcon",
          })
        })
      }
    },
    fetchAllEnvironments() {
      this.$store
        .dispatch("getAllEnvironmentsByTransmissionGroup", { per_page: 100, transmissionID: this.transmissionID })
        .then((response) => {
          const transmissionId = this.transmissionID

          let selectedEnv = localStorage.getItem(
            `currentEnv-${transmissionId}`
          );
          if (selectedEnv) {
            selectedEnv = JSON.parse(selectedEnv);

            const res = response.items.filter((value) => {
              return value.id == selectedEnv.id;
            });

            if (res.length == 0) {
              selectedEnv = undefined;
            }
          }

          if (!selectedEnv) {
            selectedEnv = response.items[0] || undefined;
          }

          this.currentEnv = selectedEnv;
          this.options = response.items;
        })
        .catch((error) => {
          makeToast({
            title: this.$t("integrator.toast.confirm_install.error.title"),
            text: this.$t("integrator.toast.confirm_install.error.message"),
            variant: "danger",
            icon: "CheckIcon",
          })
        });
    },
    buildButtons() {
      this.buttons = [
        {
          text: this.$t("transmission.nav.central"),
          variant: "outline-primary",
          icon: "AlertCircleIcon",
          items: [
            {
              text: this.$t("transmission.nav.info"),
              variant: "outline-secondary",
              icon: "AlertCircleIcon",
              action: {
                name: "transmission-info",
                params: {
                  groupId: this.transmissionID,
                },
              },
            },
            {
              text: this.$t("transmission.nav.dashboard"),
              variant: "outline-success",
              icon: "ActivityIcon",
              action: {
                name: "transmission-dashboard",
              },
            },
          ],
        },
        {
          text: this.$t("transmission.nav.setup"),
          variant: "outline-warning",
          icon: "BriefcaseIcon",
          items: [
            {
              text: this.$t("transmission.nav.events"),
              variant: "outline-primary",
              icon: "RadioIcon",
              action: {
                name: "events",
              },
            },
            {
              text: this.$t("transmission.nav.services"),
              variant: "outline-info",
              icon: "BriefcaseIcon",
              action: {
                name: "services",
              },
            },
            {
              text: this.$t("transmission.nav.environments"),
              variant: "outline-warning",
              icon: "MapPinIcon",
              action: {
                name: "environments",
              },
            },
            {
              text: this.$t("transmission.nav.scripts"),
              variant: "outline-secondary",
              icon: "FileIcon",
              action: {
                name: "scripts",
              },
            },
          {
            text: this.$t("transmission.nav.apps"),
            variant: "outline-info",
            icon: "GlobeIcon",
            action: {
              name: "integrate-apps",
            },
          },
          ],
        },
        {
          text: this.$t("transmission.nav.activators"),
          variant: "outline-info",
          items: [
            {
              text: this.$t("transmission.nav.webhooks"),
              variant: "outline-info",
              icon: "LinkIcon",
              action: {
                name: "transmission-webhooks",
              },
            },
            {
              text: this.$t("transmission.nav.scheduler"),
              variant: "outline-info",
              icon: "ClockIcon",
              action: {
                name: "transmission-scheduler",
              },
            },
          ]
        },
        {
          text: this.$t("transmission.nav.middlewares"),
          variant: "outline-danger",
          icon: "GitPullRequestIcon",
          action: {
            name: "middlewares",
          },
        },
      ];
    },
    openDropdown(ref){
      clearTimeout(this.dropdownTimer)
      this.$refs[ref][0].show()
    },
    delayCloseDropdown(ref){
      clearTimeout(this.dropdownTimer)
      this.dropdownTimer = setTimeout(() => {
        this.$refs[ref][0].hide()
      }, 300);
    }
  },
};
</script>

<style lang="scss" scoped>
.env-selection {
  width: 250px;
}
</style>