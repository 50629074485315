<template>
  <div>
    <div class="run-metadata-container">
      <div class="d-flex align-items-start justify-content-between d-flex">
        <h3>Run Metadata:</h3>
        <div class="small ">
          Executed: <span class="font-weight-bold">{{this.formataDate(run.created_at)}}</span>
        </div>
      </div>

      <div class="text-light d-flex flex-wrap justify-content-start align-items-start">

        <div class="border rounded p-1 w-50">
          <h5 class="mb-0 font-weight-bolder">Trigger Parameters:</h5>
          <hr class="mb-75 mt-50">
          <div class="d-flex align-items-center flex-wrap">
            <div v-for="(value, key, idx) in getGeneralMetadata()" :key="`metadata-field-${key}-${idx}-${uuid}`" class="mr-3">
              <span v-if="value && value.value">
                <b-icon v-if="value.icon" :icon="value.icon" class="mr-25" variant="light" scale="0.8"/>
                <!-- <b-icon v-else icon="square" class="mr-25 invisible" variant="light" scale="0.8"/> -->
                <span class="font-weight-bold">{{ key }}: </span>{{ value.value }}
              </span>
            </div>
          </div>
          <div class="px-50 mt-1 ">
            <div class="font-weight-bold received-body-title">
              Received Body <span class="small text-secondary font-weight-bold">( $.DATA ) </span>:
            </div>
            <custom-pre :data="getTriggerBody()" minHeight="200px" maxHeight="200px"  class="received-body-radius"/>
          </div>
        </div>
        <div class="pl-2 d-flex flex-wrap align-items-center justify-content-start w-50">

          <div class="px-50 w-100 mb-1">
            <div class="font-weight-bold received-body-title">
              Flux Return <span class="small text-secondary font-weight-bold">( $.RETURN ) </span>:
            </div>
            <custom-pre :data="getReturnBody()" minHeight="200px" maxHeight="200px" class="received-body-radius"/>
          </div>

          <div class="font-weight-bolder mr-1 border rounded px-1 py-50 mb-1">
            <b-icon icon="stopwatch" variant="" scale="1" class="mr-25 my-0"/>
            <span>
              Execution Time: <b class="text-info">{{ (run.time / (1000 * 1000)).toFixed(2) }}s</b>
            </span>
          </div>

          <div class="font-weight-bolder border rounded px-1 py-50 mr-1 mb-1">
            Run Status:             
            <span class="text-success" v-if="run.success">
              <b-icon icon="check" class="m-0 p-0" scale="1.4" variant="success"/>
              SUCCESS 
            </span>
            <span class="text-danger" v-else>
              <b-icon icon="x" class="m-0 p-0" scale="1.4" variant="danger"/>
              ERROR 
            </span>
          </div>

          <div class="font-weight-bolder mr-1 border rounded px-1 py-50 mb-1">
            <b-icon icon="card-heading" variant="" scale="1" class="mr-25"/>
            <span>
              Execution Message: <span :class="run.success ? 'text-success' : 'text-danger'">{{run.message}}</span>
            </span>
          </div>
        </div>
    
      </div>
    </div>

    <div class="d-flex align-items-end justify-content-between flex-wrap">
      <h3 class="mt-2">Execution Logs:</h3>
    </div>
    <run-logs :runLog="run.json_return.logs" :wasSuccessfull="run.success" v-if="run.json_return.logs"/>
    <div v-else>
      <b-skeleton height="500px"/>
    </div>
 
  </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BIcon,
  BBadge,
  BButton,
  BSkeleton,
} from "bootstrap-vue";
import moment from "moment";
import {copyText} from "@/custom/class/FunctionClasses/CommonToasts.js";
import RunLogs from "@/layouts/components/Terminal/TerminalTabs/Run/Refactored/RunLogs.vue"
import CustomPre from '@/views/pages/middleware/MiddlewareManagement/Components/CustomPre.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {  
    BCard,
    BCollapse,
    BIcon,
    BBadge,
    BButton,
    RunLogs,
    BSkeleton,
    CustomPre
  },
  data() {
    return {
      uuid: uuidv4()
    }
  },
  props: {
    run: {
      type: Object,
      default: undefined 
    },
    isTerminal: {
      type: Boolean,
      default: false,
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      
    },
    formataDate(date) {
      date = new Date(date)
      return new moment(date).calendar()
    },
    getGeneralMetadata(){
    
      let r = {}
      const data = structuredClone(this.run)

      r['Sender'] = {value: data.sender, icon: "box-arrow-up-right"}
      r['Event'] = {value: data.event, icon: "broadcast"}
      r['Receiver'] = {value: data.receiver, icon: "box-arrow-in-down-right"}

      return r
    },
    copyText(text){
      if (typeof text != 'string'){
        text = JSON.stringify(text)
      }
      copyText(text)
    },
    getReturnBody(){
      return this.run.json_return?.info?.RETURN?.BODY 
    },
    getTriggerBody(){
      return this.run.json_return?.info?.DATA?.BODY
    }
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.received-body-radius{
  border-radius: 0 5px 5px 5px !important;
}
.received-body-title{
  background-color: $pre-bg ;
  width: fit-content;
  padding: 1px 8px;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid $dark;
}
</style>