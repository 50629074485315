<template>
  <div>

    <span>
      <b-link @click="$refs['patch-notes-modal'].show()" v-if="currentSystemVersion">
        <fiqon-logo class="mt-25 mr-25" :size="16"/>
        <span class="text-secondary font-weight-bold small">System Version {{currentSystemVersion}}</span>
      </b-link>
      <!-- Numeração de versões divida em 3 secções: " A . B.C " -->
      <!-- A : refere mudanças grandes à arquitetura do sistema - Frequentemente significa que fluxos na versão mais nova não serão compativeis com a anterior -->
      <!-- B : refere a mudanças menores no sistema - Normalmente muda a cada 2 semanas de avanços (período de Sprint) -->
      <!-- C : refere a mudanças muito pequenas - Normalmente hotfixes ou patches -->
    </span>


  <b-modal
    ref="patch-notes-modal"
    centered
    hide-footer
    size="xl"
    body-class="patch-notes-modal-content round"
    title-class="w-100"
  >
    
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3>
            <span class="font-weight-bolder text-success">
              FiqOn
            </span> 
            Release Notes
            <b-iconstack scale="0.7">
              <b-icon icon="pencil-fill" shift-h="6" shift-v="2" scale="0.7"/>
              <b-icon icon="journal"/>
            </b-iconstack>
          </h3>
        </div>
        <div class="w-50 d-flex justify-content-end align-items-center">
          <span class="text-secondary small mr-1 font-weight-bolder">Version:</span> 
          <b-form-select size="sm" class="w-25 mr-2" :options="versionList" v-model="selectedVersion" v-if="selectedVersion" @input="getSelectedVersion()"/>
        </div>
      </div>
    </template>

    <b-collapse :visible="true" appear :key="'collapse-'+vKey">
      <div class="patch-notes-body" v-if="versionDoc">
        <div v-for="(category, idx) in Object.keys(versionDoc.data)" :key="'patch-notes-header-'+category">
          <div class="category">
            <h4 class="">
              {{category}}:  
            </h4>
            <ul class="section">   
              <li v-for="itm in Object.keys(versionDoc.data[category])">
                <fiqon-logo :size="inlineFiqonLogoSize"/>
                <b> {{itm}}</b> 
                <template v-if="versionDoc.data[category][itm]">
                  <b>:</b>
                {{versionDoc.data[category][itm]}}
                </template>
              </li>
            </ul>
          </div>

          <div class="d-flex  align-items-center px-5" v-if="idx != Object.keys(versionDoc.data).length-1">
            <hr class="w-50 mx-50">
            <b-icon icon="circle-fill" variant="secondary" scale="0.3"/>
            <hr class="w-50 mx-50">
          </div>
        </div>
      </div>
    </b-collapse>
  </b-modal>
  </div>
</template>

<script>
import { 
  BLink, 
  BCard, 
  BCol, 
  BRow, 
  BModal,
  BFormSelect,
  BIconstack,
  BCollapse
 } from 'bootstrap-vue'
import FiqonLogo from '@/views/pages/middleware/MiddlewareManagement/Components/ComponentSVGs/FiqonLogo.vue'
import vSelect from 'vue-select'


export default {
  components: {
    BLink,
    BCard,
    BCol, 
    BRow,
    FiqonLogo,
    BModal,
    vSelect,
    BFormSelect,
    BIconstack,
    BCollapse
  },
  data() {
    return {
      currentSystemVersion: undefined,
      selectedVersion: undefined,
      inlineFiqonLogoSize: 14,
      versionDoc: undefined,
      versionList: undefined,
      vKey: 1,
    }
  },
  computed: {
  },
  mounted () {
    this.init();
  },
  methods: {
    async init() {
      // this.selectedVersion = structuredClone(this.currentSystemVersion)
      await this.listAllVersions();

      this.showOnUpdate();
      this.getSelectedVersion();
    },
    getSelectedVersion(){
      import(`@/custom/patchNotes/patchNotesArchive/${this.selectedVersion}.json`).then((r)=>{
        this.versionDoc = r.default
        this.vKey = this.vKey +1 
      })
    },
    showOnUpdate(){
      let v = localStorage.getItem('SystemVersion')
      if (v && v != this.currentSystemVersion){
        this.$refs['patch-notes-modal'].show()
      }
      localStorage.setItem('SystemVersion', this.currentSystemVersion);
    },
    async listAllVersions(){
      let files = require.context(
        `@/custom/patchNotes/patchNotesArchive/`,
        true,
        /^.*\.json$/
      ).keys()

      let r = []
      files.forEach(f => {
        f = f.substring(2)
        f = f.substring(0, f.length-5)
        r.push(f)
      });

      r.sort()
      r = r.reverse()
      
      this.versionList = r
      this.currentSystemVersion = r[0];
      this.selectedVersion = structuredClone(this.currentSystemVersion)
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.patch-notes-modal-content{
  max-height: 80vh;
  overflow-y: auto;
}

</style>

<style lang="scss" scoped>

.on-top {
  z-index: 1032 !important;
}
.small-logo{
  max-width: 16px;
  max-height: 16px;
}




.patch-notes-body{
  min-height: 300px;
  padding: 10px;

  .category{
    margin-bottom: 10px;  
  }

  h4{
    margin-bottom: 3px;
    margin-left: 5px;
  }

  .section{
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px 10px 10px 0;
    border-radius: 0px 10px 10px 0;
    margin-left: 5px;
    border-left: 3px solid var(--dark-blue);
    list-style-type: none;
    li{
      margin-left: 10px;
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }

}
</style>
