export default class EnumAgents {
    constructor() {
        this.items = [
            {
                "id": 0,
                "component": "agent-metadata",
                "label": "",
                "i18Key": "",
                "active": false,
            },
            {
                "id": 1,
                "component": "agent-add",
                "label": "agent.list.add.title",
                "i18Key": "add",
                "active": true,
            },
            {
                "id": 2,
                "component": "agent-subtract",
                "label": "agent.list.subtract.title",
                "i18Key": "subtract",
                "active": true,
            },
            {
                "id": 3,
                "component": "agent-remap",
                "label": "agent.list.remap.title",
                "i18Key": "remap",
                "active": true,
            },
            {
                "id": 4,
                "component": "agent-equal",
                "label": "agent.list.equal.title",
                "i18Key": "equal",
                "active": true,
            },
            {
                "id": 5,
                "component": "agent-not-equal",
                "label": "agent.list.not_equal.title",
                "i18Key": "not_equal",
                "active": true,
            },
            {
                "id": 6,
                "component": "agent-greater",
                "label": "agent.list.greater.title",
                "i18Key": "greater",
                "active": true,
            },
            {
                "id": 7,
                "component": "agent-greater-equal",
                "label": "agent.list.greater_equal.title",
                "i18Key": "greater_equal",
                "active": true,
            },
            {
                "id": 8,
                "component": "agent-less-than",
                "label": "agent.list.less_than.title",
                "i18Key": "less_than",
                "active": true,
            },
            {
                "id": 9,
                "component": "agent-less-than-equal",
                "label": "agent.list.less_than_equal.title",
                "i18Key": "less_than_equal",
                "active": true,
            },
            {
                "id": 10,
                "component": "agent-delete",
                "label": "agent.list.delete.title",
                "i18Key": "delete",
                "active": true,
            },
            {
                "id": 11,
                "component": "agent-exists",
                "label": "agent.list.exists.title",
                "i18Key": "exists",
                "active": true,
            },
            {
                "id": 12,
                "component": "agent-not-exists",
                "label": "agent.list.not_exists.title",
                "i18Key": "not_exists",
                "active": true,
            },
            {
                "id": 13,
                "component": "agent-concatenate",
                "label": "agent.list.concatenate.title",
                "i18Key": "concatenate",
                "active": true,
            },
            {
                "id": 14,
                "component": "agent-array-append",
                "label": "agent.list.array_append.title",
                "i18Key": "array_append",
                "active": true,

            },
            {
                "id": 15,
                "component": "agent-array-filter",
                "label": "agent.list.array_filter.title",
                "i18Key": "array_filter",
                "active": true,
            },
            {
                "id": 16,
                "component": "agent-array-max",
                "label": "agent.list.array_max.title",
                "i18Key": "array_max",
                "active": true,

            },
            {
                "id": 17,
                "component": "agent-array-min",
                "label": "agent.list.array_min.title",
                "i18Key": "array_min",
                "active": true,
            },
            {
                "id": 18,
                "component": "agent-execute-javascript",
                "label": "agent.list.execute_javascript.title",
                "i18Key": "execute_javascript",
                "active": true,
                "width": "80%",
                "closedVariablesPannelInit": true
            },
            {
                "id": 19,
                "component": "agent-xml-decode",
                "label": "agent.list.xml_decode.title",
                "i18Key": "xml_decode",
                "active": true,
            },
            {
                "id": 20,
                "component": "agent-xml-encode",
                "label": "agent.list.xml_encode.title",
                "i18Key": "xml_encode",
                "active": true,

            },
            {
                "id": 21,
                "component": "agent-json-decode",
                "label": "agent.list.json_decode.title",
                "i18Key": "json_decode",
                "active": true,
            },
            {
                "id": 22,
                "component": "agent-json-encode",
                "label": "agent.list.json_encode.title",
                "i18Key": "json_encode",
                "active": true,
            },
            {
                "id": 23,
                "component": "agent-string-case-parser",
                "label": "agent.list.string_case_parser.title",
                "i18Key": "string_case_parser",
                "active": true,
            },
            {
                "id": 24,
                "component": "agent-string-replace",
                "label": "agent.list.string_replace.title",
                "i18Key": "string_replace",
                "active": true,
            },
            {
                "id": 25,
                "component": "agent-third-party-token",
                "label": "agent.list.third_party_token.title",
                "i18Key": "third_party_token",
                "active": true,
            },
            {
                "id": 26,
                "component": "agent-multiplication",
                "label": "agent.list.multiplication.title",
                "i18Key": "multiplication",
                "active": true,
            },
            {
                "id": 27,
                "component": "agent-division",
                "label": "agent.list.division.title",
                "i18Key": "division",
                "active": true,
            },
            {
                "id": 28,
                "component": "agent-map-keys",
                "label": "agent.list.map_keys.title",
                "i18Key": "map_keys",
                "active": true,
            },
            {
                "id": 29,
                "component": "agent-debug-payload",
                "label": "agent.list.debug_payload.title",
                "i18Key": "debug_payload",
                "active": true,
            },
            {
                "id": 30,
                "component": "agent-array-length",
                "label": "agent.list.array_length.title",
                "i18Key": "array_length",
                "active": true,
            },
            {
                "id": 31,
                "component": "agent-mod",
                "label": "agent.list.mod.title",
                "i18Key": "mod",
                "active": true,
            },
            {
                "id": 32,
                "component": "agent-logic-expression",
                "label": "agent.list.logic_expression.title",
                "i18Key": "logic_expression",
                "active": true,
            },
            {
                "id": 33,
                "component": "agent-file-write",
                "label": "agent.list.file_write.title",
                "i18Key": "file_write",
                "active": true,
            },
            {
                "id": 34,
                "component": "agent-file-read",
                "label": "agent.list.file_read.title",
                "i18Key": "file_read",
                "active": true,
            },
            {
                "id": 35,
                "component": "agent-file-seek",
                "label": "agent.list.file_seek.title",
                "i18Key": "file_seek",
                "active": true,
            }, 
            {
                "id": 36,
                "component": "agent-regex-match",
                "label": "agent.list.regex_match.title",
                "i18Key": "regex_match",
                "active": true,
            },
            {
                "id": 37,
                "component": "agent-file-read-line",
                "label": "agent.list.file_read_line.title",
                "i18Key": "file_read_line",
                "active": true,
            },
            {
                "id": 38,
                "component": "agent-string-section",
                "label": "agent.list.string_section.title",
                "i18Key": "string_section",
                "active": true,
            },
            {
                "id": 39,
                "component": "agent-set-status-code",
                "label": "agent.list.set_status_code.title",
                "i18Key": "set_status_code",
                "active": true,
            },
            {
                "id": 40,
                "component": "agent-date-to-timestamp",
                "label": "agent.list.date_to_timestamp.title",
                "i18Key": "date_to_timestamp",
                "active": true,
            },
            {
                "id": 41,
                "component": "agent-is-null",
                "label": "agent.list.is_null.title",
                "i18Key": "is_null",
                "active": true,
            },
            {
                "id": 42,
                "component": "agent-string-to-float",
                "label": "agent.list.string_to_float.title",
                "i18Key": "string_to_float",
                "active": true,
            },
            {
                "id": 43,
                "component": "agent-string-trim",
                "label": "agent.list.string_trim.title",
                "i18Key": "string_trim",
                "active": true,
            },
            {
                "id": 44,
                "component": "agent-timestamp-add",
                "label": "agent.list.timestamp_add.title",
                "i18Key": "timestamp_add",
                "active": true,
            },
            {
                "id": 45,
                "component": "agent-timestamp-to-date",
                "label": "agent.list.timestamp_to_date.title",
                "i18Key": "timestamp_to_date",
                "active": true,
            },
            {
                "id": 46,
                "component": "agent-logger",
                "label": "agent.list.logger.title",
                "i18Key": "logger",
                "active": true,
            },            
            {
                "id": 47,
                "component": "agent-delay",
                "label": "agent.list.delay.title",
                "i18Key": "delay",
                "active": true,
            },            
            {
                "id": 48,
                "component": "agent-random-integer",
                "label": "agent.list.random_integer.title",
                "i18Key": "random_integer",
                "active": true,
            },            
            {
                "id": 49,
                "component": "agent-random-string",
                "label": "agent.list.random_string.title",
                "i18Key": "random_string",
                "active": true,
            },            
            {
                "id": 50,
                "component": "agent-uuid",
                "label": "agent.list.uuid.title",
                "i18Key": "uuid",
                "active": true,
            },                   
            {
                "id": 51,
                "component": "agent-set-response-header",
                "label": "agent.list.set_response_header.title",
                "i18Key": "set_response_header",
                "active": true,
            },  
            {
                "id": 52,
                "component": "agent-get-response-header",
                "label": "agent.list.get_response_header.title",
                "i18Key": "get_response_header",
                "active": true,
            },       
            {
                "id": 53,
                "component": "agent-delete-response-header",
                "label": "agent.list.delete_response_header.title",
                "i18Key": "delete_response_header",
                "active": true,
            },                 
            {
                "id": 54,
                "component": "agent-string-explode",
                "label": "agent.list.string_explode.title",
                "i18Key": "string_explode",
                "active": true,
            },
            {
                "id": 55,
                "component": "agent-verify-true",
                "label": "agent.list.verify_true.title",
                "i18Key": "verify_true",
                "active": true,
            },
            {
                "id": 56,
                "component": "agent-verify-false",
                "label": "agent.list.verify_false.title",
                "i18Key": "verify_false",
                "active": true,
            },
            {
                "id": 57,
                "component": "agent-string-length",
                "label": "agent.list.string_length.title",
                "i18Key": "string_length",
                "active": true,
            },
            {
                "id": 58,
                "component": "agent-string-insert",
                "label": "agent.list.string_insert.title",
                "i18Key": "string_insert",
                "active": true,
            },
            {
                "id": 59,
                "component": "agent-url-encode",
                "label": "agent.list.url_encode.title",
                "i18Key": "url_encode",
                "active": true,
            },
            {
                "id": 60,
                "component": "agent-url-decode",
                "label": "agent.list.url_decode.title",
                "i18Key": "url_decode",
                "active": true,
            },
            {
              "id": 61,
              "component": "agent-remap-array-value",
              "label": "agent.list.remap_array_value.title",
              "i18Key": "remap_array_value",
              "active": true,
              "width": "40%"
            },
            {
                "id": 62,
                "component": "agent-delete-duplicate",
                "label": "agent.list.delete_duplicate.title",
                "i18Key": "delete_duplicate",
                "active": true,
            },        
        ];   
    }
}