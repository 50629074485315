<template>
  <div>
    <div align="right" class="mx-50 mb-25">
      <b-badge variant="light-warning" v-if="numberOfErrors[0]>0" class="mt-50 mx-50">
        <span class="bg-warning text-black px-25 round">{{numberOfErrors[0]}}</span>
        Minor Error<template v-if="numberOfErrors[0] > 1">s</template> 
      </b-badge>

      <b-badge variant="light-danger" v-if="numberOfErrors[1]>0" class="mt-50 mx-50">
        <span class="bg-danger text-black px-25 round">{{numberOfErrors[1]}}</span>
         Critical Error<template v-if="numberOfErrors[1] > 1">s</template> 
      </b-badge>
    </div>

    <div v-if="iteratedLogs && iteratedLogs.length > 1" class="ml-5 mb-50">
        <div class="d-flex align-items-center justify-content-start ml-1"> 
          <div class="mr-1 text-secondary small">
            Iterations
          </div>
          <b-button variant="none" class="mr-1 toggle-all-iterations-btn" @click="toggleAllIterations(false)"> 
            <b-icon icon="arrows-angle-contract" scale="0.8"/>
          </b-button>
          <b-button variant="none" class="toggle-all-iterations-btn" @click="toggleAllIterations(true)">
            <b-icon icon="arrows-fullscreen" scale="0.8"/>
          </b-button>

        </div>
    </div>
    <div>
      <div v-for="(iteration, iterationIdx) in iteratedLogs" :key="`iteration-for-${iterationIdx}-${uuid} `"> 

        <div :class="{'runlist-margin': !isRoot, 'iteration-container': iteratedLogs.length > 1}">

          <div v-if="iteratedLogs.length > 1" class="iteration-title cursor-pointer" @click="visibleIterations['iteration-'+iterationIdx] = ! visibleIterations['iteration-'+iterationIdx]"> 
            <span class="font-weight-bold">
              LOOP ITERATION <span class="font-weight-bolder ml-50">[ {{ iterationIdx+1 }} ]</span>
            </span>
            <b-icon icon="chevron-down" class="m-0 ml-1 icon-transition" :class="{'upside-down': visibleIterations['iteration-'+iterationIdx]}" variant="light"/>
          </div> 

          <b-collapse :visible=" iteratedLogs.length < 2 || visibleIterations['iteration-'+iterationIdx]" > <!-- collapse iteration -->
            <div v-for="(log, idx) in iteration" class="mb-1 position-relative" :key="`log-in-iteration-${iterationIdx}-${idx}-${uuid}`">
              <middleware-log
               :log="log" 
               :hasChildren="log.middlewares && log.middlewares.length > 0" 
               :timelineOpened="!hiddenArray.includes(`log-in-iteration-${iterationIdx}-${idx}-${uuid}`)"
               @toggleTimeline="toggleVisibility(`log-in-iteration-${iterationIdx}-${idx}-${uuid}`)"
              />

              <hr class="mt-1 ml-4 lower-opacity" v-if="!(isRoot && iteration.length - 1 ==  idx)">

              <template v-if="log.middlewares && log.middlewares.length > 0">
                <div>
                  <b-collapse :visible="!hiddenArray.includes(`log-in-iteration-${iterationIdx}-${idx}-${uuid}`)" >
                    <run-log-list :logs="log.middlewares"/>
                  </b-collapse>
                  <div class="log-connector-line"/>
                </div>
              </template>
            </div>
          </b-collapse>


        </div>

      </div>
    </div>

 </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BIcon,
  BBadge,
  BButton,
} from "bootstrap-vue";
import moment from "moment";
import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareLog from "@/layouts/components/Terminal/TerminalTabs/Run/Refactored/MiddlewareLog.vue"
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {  
    BCard,
    BCollapse,
    BIcon,
    BBadge,
    BButton,
    MiddlewareLog,
    RunLogList: () => import("@/layouts/components/Terminal/TerminalTabs/Run/Refactored/RunLogList.vue")
  },
  data() {
    return {
      iteratedLogs: undefined,
      visibleIterations: {},
      uuid: uuidv4(),
      hiddenArray: []
    }
  },
  props: {
    logs: {
      type: [Object, Array],
      default: undefined 
    },
    isRoot:{
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    numberOfErrors() {
      const nonFatal = this.logs.filter((el)=> el.error == true && el.is_fatal == false ).length
      const fatal = this.logs.filter((el)=> el.error == true && el.is_fatal == true ).length
      
      return [nonFatal, fatal]
    }
  },
  methods: {
    init(){
      this.checkForIterations()
    },
    checkForIterations(){
      const logs = structuredClone(this.logs)
      let first = logs[0]
      let r = [];
      let temp = [];
      let visibleIterations = {}

      for (let idx = 0; idx < logs.length; idx++) {
        if (logs[idx].id == first.id && idx > 0){
          r.push(temp);
          temp = []
        }
        temp.push(logs[idx])
      }
      r.push(temp)

      let itIdx = 0
      r.forEach((el)=>{
        visibleIterations['iteration-'+itIdx] = true  
        itIdx = itIdx + 1
      })

      this.visibleIterations = visibleIterations

      this.iteratedLogs = r;
    },
    formataDate(date) {
      date = new Date(date)
      return new moment(date).calendar()
    },
    middlewareType(enumId){
      return new Middlewares().items.find(el => el.id == enumId)
    },
    toggleVisibility(key){
      let idx = this.hiddenArray.findIndex(el => el == key)
      if (idx > -1){
        this.hiddenArray.splice(idx, 1)
      } else {
        this.hiddenArray.push(key)
      }
    },
    toggleAllIterations(state){
      let temp = {}
      let idx = 0
      this.iteratedLogs.forEach(itr => {
        temp['iteration-' + idx] = state
        idx = idx + 1;
      });

      this.visibleIterations = structuredClone(temp)
    }

  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.toggle-all-iterations-btn{
  background-color: $iteration-color;
  padding: 3px 4px;
  color: $light;
  border: 1px solid $black;
  transition: all 0.15s;
  &:hover, &:focus{
    box-shadow: 0 0 3px $dark;
  }
  &:active{
    border-color: $dark;
  }
  
}
.icon-transition{
  transition: 0.2s;
  &.upside-down{
    rotate: 180deg;
  }
}

.runlist-margin{
  margin-left: 40px;
}
.lower-opacity{
  opacity: 0.4;
}

.log-connector-line{
  position: absolute;
  height: 100%;
  width: 15px;
  
  background-color: $iteration-color;
  left: 29px;
  top: 30px;
  z-index: -1;
}
.iteration-container{
  $iteration-border: 4px solid $iteration-color;
  //width: fit-content;
  border-left: $iteration-border;
  border-top: $iteration-border;
  
  .iteration-title{
    width: fit-content;
    padding: 0 5px;
    border-bottom: $iteration-border;
    border-right: $iteration-border;
    background-color:  $iteration-color;
    margin-bottom: 5px;
  }
}
</style>