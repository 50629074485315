<template>
  <div>
    <b-collapse :visible="cardIsVisible">
      
      <div class="my-1">
        
        <div class="error-run-container">
          <div class="run-title d-flex justify-content-between align-items-end">
            <span class="mx-50">[ERROR]- Run Failed to Execute</span>
            <b-button class="py-0 px-50" variant="none" @click="cardIsVisible = false">
              <b-icon icon="x" variant="black" scale="1.3"/>
            </b-button>
          </div>
          <div class="run-body"> 
            
            <template v-for="field in shownFields">
              <div v-if="!(typeof run.data[field] == 'undefined' || run.data[field] === null)" class="">
                <b-icon icon="square-fill" scale="0.3" shift-v="-2"/>
                <span class="mr-1 font-weight-bolder text-capitalize">{{field}}:</span> 
                <span class="text-dark">{{run.data[field]}}</span>
              </div>
            </template>
            <div>
              <b-icon icon="clock" scale="0.8" shift-v="-2" class="mr-25"/>
              <span class="mr-1 font-weight-bolder text-capitalize">Time Running:</span> 
              <span class="text-dark">{{ (run.time/1000).toFixed(2) }} seconds</span>
            </div>

            <div class="position-relative">
              <div align="right">
                <b-button class="py-25 px-75 mx-50" variant="outline-dark" @click="showDetails = ! showDetails">
                  <span class="font-weight-bolder" v-if="!showDetails">Show Details</span>
                  <span class="font-weight-bolder" v-else>Hide Details</span>
                </b-button>  
              
              </div>
              <b-collapse :visible="showDetails">
                <div class="run-details">
                  <pre class="bg-transparent">{{JSON.stringify(run.data, null, 2)}}</pre>

                  <b-button class="copy-btn" variant="secondary" @click="copyRun()">
                    Copy
                  </b-button>
                </div>
              </b-collapse>

            </div>
          </div>
        
        </div>  
      
      </div>
    
    </b-collapse> 
  </div>
</template>

<script>
import { BCollapse, BButton } from "bootstrap-vue";
import JsonViewer from "vue-json-viewer";
import Run from "./class/run";
import moment from "moment";
import { copyToast } from '@/custom/class/FunctionClasses/CommonToasts';

export default {
  components: {
    JsonViewer,
    BCollapse,
    BButton,
  },
  props: {
    run: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDetails: false,
      cardIsVisible: true,
      shownFields: ['message','name','code','status']
    };
  },
  methods: {
    formatDate(value) {
      if (!value) return null
      let date = new Date(value)
      return moment(date).format(this.$t('scheduler.view.moment_date_format'));
    },
    closeThisCard(){
      this.cardIsVisible = false
    },
    copyRun(){
      let c = JSON.stringify(this.run.data)
      navigator.clipboard.writeText(c);
      copyToast()
    }
  },
};
</script>

<style lang="scss" scoped>
.error-run-container{

  .run-title{
    background-color: var(--danger);
    color: black;
    text-align: center;
    font-weight: bold;
    border-radius: 5px 5px 0 0 ;
    padding: 1px 5px;
         
  }
  .run-body{
    padding: 5px;
    border: 1px solid var(--danger);
    border-top: none;
    border-radius: 0 0 5px 5px;
    color: var(--danger);
    background-color: rgb(10, 10, 10)
  }

  .run-details{
    max-height: 300px;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid var(--secondary);
    border-radius: 8px;
    padding: 10px;
    margin: 5px;
    pre{
      background-color: transparent !important;
      font-family: monospace;
      font-size: 13px;
    }
    .copy-btn{
      position: absolute;
      bottom: 5px;
      right: 10px;
      padding: 3px 10px;
      opacity: 0.7;
    }
  }
}

</style>