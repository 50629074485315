<template>
  <b-container fluid>
    <validation-observer ref="test-script">
      <b-row>
        <b-col cols="12" md="6">
        
          <b-form @submit.prevent>
          
            <b-row>
              <b-col cols="4">
                <b-form-group :label="$t('terminal_tests.select.sender')" label-for="service-select">
                  <validation-provider
                    #default="{ errors }"
                    name="sender"
                    vid="service"
                    rules="required"
                  >
                    <v-select
                      id="service-select"
                      v-if="transmissionServices"
                      :options="transmissionServices"
                      :reduce="(item) => item"
                      v-model="selectedService"
                      label="identifier"
                      @input="getEventByService"
                      :clearable="false"
                      ref="v-select-base"
                    >
                      <template #selected-option="data">
                        <span class="text-truncate ellipsing-select-span" :style="`max-width: ${getSelectSize()}px ;`">{{ data.identifier }}</span>
                      </template>

                      <template #option="{ identifier }">
                        <span>{{ identifier }}</span>
                      </template>
                      <template slot="no-options"> <span>{{$t("terminal_tests.terms.no_options")}}</span> </template>
                    </v-select>
                    <b-skeleton type="input" v-else/>
                    <small name="service-select" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group :label="$t('terminal_tests.select.event')" label-for="event-select">
                  <validation-provider
                    #default="{ errors }"
                    name="event"
                    vid="event-select"
                    rules="required"
                  > 
                    <v-select
                      id="event-select"
                      v-if="eventLoading == false"
                      :disabled="!selectedService"
                      :options="selectAllEventsOptions"
                      v-model="selectedEvent"
                      @input="getEventObjectField"
                      label="event"
                      :clearable="false"
                    >
                      <template #selected-option="data">
                        <!-- <span class="text-truncate">{{ data.event_identifier }}</span> -->
                        <span class="text-truncate ellipsing-select-span" :style="`max-width: ${getSelectSize()}px ;`">{{ data.event_identifier }}</span>
                      </template>
                      
                      <template #option="data">
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <span>{{ data.event_identifier }}</span>
                          </div>
                          
                          <div class="scale-down-event-info">
                          
                            <b-icon v-if="data.can_receive" icon="box-arrow-in-down-left" variant="success" scale="0.6" class="margin-right-minus" :id="'event-info-receive-'+data.id"/>
                            <b-icon v-else icon="box-arrow-in-down-left" variant="danger" scale="0.6" class="margin-right-minus" :id="'event-info-receive-'+data.id"/>

                            <b-icon v-if="data.can_send" icon="box-arrow-up-right" variant="success" scale="0.55" :id="'event-info-send-'+data.id"/>
                            <b-icon v-else icon="box-arrow-up-right" variant="danger" scale="0.55" class="" :id="'event-info-send-'+data.id"/>
                            
                            <b-tooltip :target="'event-info-receive-'+data.id" triggers="focus hover" placement="left" >
                              <span v-if="data.can_receive">can receive</span>
                              <span v-else>can't receive</span>
                            </b-tooltip>

                            <b-tooltip :target="'event-info-send-'+data.id" triggers="focus hover" placement="left">
                              <span v-if="data.can_send">can send</span>
                              <span v-else>can't send</span>
                            </b-tooltip>
                          </div>
                        
                        </div>
                      </template>

                      <template slot="no-options"> <span>{{$t("terminal_tests.terms.no_options")}}</span> </template>                      
                    </v-select>
                    <b-skeleton type="input" v-if="eventLoading == true"/>
                    <small name="username" class="text-danger">
                      {{errors[0]}}
                  </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  :label="$t('terminal_tests.select.receiver')"
                  label-for="service-select-receiver"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="receiver"
                    vid="receiver"
                    rules="required"
                  >
                    <v-select
                      id="service-select-receiver"
                      v-if="receiverLoading == false"
                      :disabled="!selectedEvent"
                      :options="selectAllServicesOptions"
                      :reduce="(item) => item"
                      v-model="selectedReceiver"
                      label="identifier"
                      :clearable="false"
                    >
                      <template #selected-option="data">
                        <span class="text-truncate ellipsing-select-span" :style="`max-width: ${getSelectSize()}px ;`">{{ data.identifier }}</span>
                      </template>
                      
                      <template #option="data">
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <span>{{ data.identifier }}</span>
                          </div>
                          
                          <div class="scale-down-event-info">
                          
                            <b-icon v-if="data.can_receive" icon="box-arrow-in-down-left" variant="success" scale="0.6" class="margin-right-minus" :id="'service-info-receive-'+data.id"/>
                            <b-icon v-else icon="box-arrow-in-down-left" variant="danger" scale="0.6" class="margin-right-minus" :id="'service-info-receive-'+data.id"/>

                            <b-icon v-if="data.can_send" icon="box-arrow-up-right" variant="success" scale="0.55" :id="'service-info-send-'+data.id"/>
                            <b-icon v-else icon="box-arrow-up-right" variant="danger" scale="0.55" class="" :id="'service-info-send-'+data.id"/>
                            
                            <b-tooltip :target="'service-info-receive-'+data.id" triggers="focus hover" placement="left" >
                              <span v-if="data.can_receive">can receive</span>
                              <span v-else>can't receive</span>
                            </b-tooltip>

                            <b-tooltip :target="'service-info-send-'+data.id" triggers="focus hover" placement="left">
                              <span v-if="data.can_send">can send</span>
                              <span v-else>can't send</span>
                            </b-tooltip>
                          </div>
                        
                        </div>
                      </template>


                      <template slot="no-options"> <span>{{$t("terminal_tests.terms.no_options")}} </span> </template>
                    </v-select>
                    <b-skeleton type="input" v-if="receiverLoading == true"/>
                    <small name="service-select" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-overlay
                :show="receiverLoading == true"
                variant="transparent"
                :opacity="0.85"
                blur="2px"
                rounded="sm"
                >
                <json-editor
                  key="scoped-variables-load"
                  v-model="eventObject"
                  :modes="['code', 'tree', 'preview']"
                  :height="500"
                  proportionType="px"
                />
                </b-overlay>
              </b-col>

            </b-row>
          </b-form>
        </b-col>
        <b-col cols="6" class="mt-3 mt-md-0">

          <div class="submit-button" >
            <div class="d-flex justify-content-between align-items-end" >
              
              <div class="">
                <span class="d-inline-block low-opacity ">

                  <b-form-checkbox class="ml-25 d-inline" v-model="autoAllowPermissions" @input="toggleAutoAllowPermission()" id="auto-allow-permissions-checkbox"/>
                  <label for="auto-allow-permissions-checkbox">
                    Auto-allow permissions
                     <helper-tooltip tooltipPlacement="right" class="scale-down-tooltip" size="12" innter
                      innerText="Automatically give permissions (send / receive) to the refered Service and Event when you first Run them on the Terminal"
                    />
                  </label>
                </span>
              </div>
              
              <div align="center" class="d-flex align-items-end">
                <span class="small text-secondary mr-1 mb-50 cursor-pointer" @click="$refs['confirm-quickrun-modal'].show()">
                  Quick Run:
                  (Ctrl + Shift + R)
                </span>

                <countdown-button
                  :countdown="5"
                  type="submit"
                  class="px-4 mb-50"
                  variant="success"
                  :validateClick="validateSubmit"
                  @click="handleSubmit"
                  :loading="runningTest"
                >
                  <template #countdown="btn">
                    <span
                      class="d-inline-block text-center counter-btn"
                      >{{ btn.currentCountdown }}</span
                    >
                  </template>
                  <template #loading>
                    <span
                      class="d-inline-block"
                      style="height: 1.8em; line-height: 1.8em"
                      ><b-spinner small
                    /></span>
                  </template>
                  <b-icon icon="play" variant="white" /> {{ $t('terminal_tests.buttons.run') }}

                </countdown-button>
              </div>
            </div>
          </div>
            
          <runs-list ref="runs-list" @addTab="addTab" />
          
        </b-col>
      </b-row>
    </validation-observer>
    
    <b-modal
      ref="add-permissions-run-modal"
      ok-variant="favorite"
      :ok-disabled="modalLoading"
      @hide="(e)=>{if (modalLoading) e.preventDefault();}"
      @ok="(e)=>{e.preventDefault(); givePermissions()}"
      centered
      size="lg"
    >
      <template #modal-title>
        <div>
          Concede Permissions to Run?
        </div>
      </template>
      <template #modal-ok>
        <span v-if="!modalLoading">Yes</span>
        <b-spinner v-else small/>
      </template>

      <p>The selected Sender, Event and Receiver don't have the needed permissions to execute.</p>

      <p v-if="selectedService && selectedEvent && selectedReceiver" class="mt-2">
        Do you wish to give the following permissions in order to Run this test? 
        <ul class="mt-1 px-3">
          <!-- <li>Service <b>{{selectedService.identifier}}</b></li> -->
          <li>
             <span class="font-weight-bolder text-success">{{selectedService.identifier}}</span>
            <span class="mx-25">
              <b-icon icon="link45deg" scale="1.15"/>
            </span>
             <span class="font-weight-bolder text-success">{{selectedEvent.event_identifier}}</span>

            <b-icon icon="arrow-right-short" variant="favorite" scale="1.5" class="mx-50"/>

            allow <span class="font-weight-bolder text-success">Send</span>
            <b-icon  icon="box-arrow-up-right" variant="success" class="mx-50"/>
          </li>
          <li class="mt-50">
            <span class="font-weight-bolder text-success">{{selectedReceiver.identifier}}</span>
            <span class="mx-25">
              <b-icon icon="link45deg" scale="1.15"/>
            </span>
            <span class="font-weight-bolder text-success">{{selectedEvent.event_identifier}}</span>

            <b-icon icon="arrow-right-short" variant="favorite" scale="1.5" class="mx-50"/>
            allow <span class="font-weight-bolder text-success">Receive</span>
            <b-icon  icon="box-arrow-in-down-left" variant="success" scale="1.1" class="mx-50"/>
          </li>
        </ul>
      </p>

      <div class="d-flex align-items-center pt-2">
        <b-form-checkbox v-model="autoAllowPermissions" @input="toggleAutoAllowPermission()"/>
        <label>
          Auto-allow permissions
          <helper-tooltip tooltipPlacement="right" class="" size="12" innter
          innerText="Automatically give permissions (send / receive) to the refered Service and Event when you first Run them on the Terminal"
        />
        </label>
      </div>

    </b-modal>

    <b-modal
      ref="confirm-quickrun-modal"
      centered
      ok-only
      ok-variant="favorite"
      title="Execute Quick Run?"
      @ok="handleSubmit()"
    >
      <p>
        <span class="font-weight-bolder text-black bg-light rounded px-50 mx-25" >Ctrl + Shift + R</span>
        <b-icon icon="arrow-right-short"/>
        The command for executing a Quick Run was pressed.
        <br>
        <span class="text-secondary">A Quick Run uses whichever Service and Event are currently selected and executes a test with them.</span>
      </p>

      <div class="d-flex align-items-center">
        <b-form-checkbox v-model="allowQuickRun" id="auto-run-shortcut-checkbox" @input="toggleAutoQuickRun"/>
        <label class="text-secondary m-0" for="auto-run-shortcut-checkbox">Automatically run without showing this again</label>
      </div>
    
      <template #modal-ok>
        <b-icon icon="play-fill"/> Run
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import {
  BTab,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BContainer,
  BForm,
  BCollapse,
  BCard,
  BCardHeader,
  BCardBody,
  BIcon,
  BSpinner,
  BSkeleton,
  BOverlay,
  BFormCheckbox,
  BBadge,
  BTooltip,
} from "bootstrap-vue";
import VSelect from "vue-select";
import JsonViewer from "vue-json-viewer";
import JsonEditor from "../../JsonEditor/JsonEditor.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { makeToast } from "@/layouts/components/Popups";
import CollapseItem from "@/layouts/components/Terminal/CollapseItem.vue";
import CountdownButton from "./../../CountdownButton.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import RunsList from "./Run/RunsList.vue";
import { mapGetters } from "vuex";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import { errorToast, successToast } from "@/custom/class/FunctionClasses/CommonToasts";

export default {
  components: {
    BForm,
    BContainer,
    BTab,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    VSelect,
    JsonViewer,
    JsonEditor,
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    CollapseItem,
    CountdownButton,
    FeatherIcon,
    BIcon,
    BSpinner,
    RunsList,
    BSkeleton,
    BOverlay,
    BFormCheckbox,
    HelperTooltip,
    BBadge,
    BTooltip
  },
  data() {
    return {
      required,
      selectedEvent: null,
      selectedService: null,
      selectedReceiver: null,
      selectReceiverOptions: [],
      selectEventsOptions: [],
      data: null,
      eventObject: {},
      testResponse: {},
      testExecutionLog: null,
      runningTest: false,
      eventLoading: false,
      receiverLoading: false,
      autoAllowPermissions: false,
      selectAllEventsOptions: undefined,
      selectAllServicesOptions: undefined,
      modalLoading: false,
      allowQuickRun: false,
    };
  },
  watch: {
    terminalRunTrigger(newValue, oldValue) {

      if (!newValue){
        return
      }
      
      this.$emit('forceTerminalOpen')
      
      if (!isNaN(newValue.senderId) && !isNaN(newValue.eventId) && !isNaN(newValue.receiverId) ){
        this.runTest(newValue)
      }
      
      this.$store.dispatch("internal/setTerminalRunTrigger", undefined)
    }
  },
  computed: {
    ...mapGetters(['getTransmissionServices', 'getTransmissionEvents']),
    ...mapGetters('internal', ['terminalRunTrigger']),
    transmissionServices(){
      return this.getTransmissionServices
    },
    transmissionEvents(){      
      return this.getTransmissionEvents
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getServicesByTransmission();

      if (!Array.isArray(this.transmissionEvents)){
        this.getEventsByTransmission();
      }

      if (localStorage.getItem('autoAllowPermissionsOnRun')){
        this.autoAllowPermissions = true
      }
      if (localStorage.getItem('allowRunShortcut')){
        this.allowQuickRun = true
      }

    },
    getEventByService() {
      this.eventLoading = true;
      this.$store
        .dispatch("getEventsByService", {
          transmissionID: this.$route.params.transmissionID,
          serviceId: this.selectedService.id,
        })
        .then((response) => {
          
          if (this.transmissionEvents){
            let tempEvtOpts = []
            let permissions = structuredClone(response)

            this.transmissionEvents.forEach(el => {
              let evtPerm = permissions.find( p=> p.event.id == el.id)
              
              if (evtPerm){
                el.can_receive = evtPerm.can_receive
                el.can_send = evtPerm.can_send
              } else {
                el.can_receive = false
                el.can_send = false
              }

              tempEvtOpts.push(el)
            });

            this.selectAllEventsOptions = tempEvtOpts
          }
          
          this.selectEventsOptions = response
          this.eventLoading = false;
          this.selectedEvent = null;
          this.selectedReceiver = null;

          let lastAccess = localStorage.getItem('lastAccessInfo')
          if (lastAccess){ 
            lastAccess = JSON.parse(lastAccess)[this.$route.params.transmissionID]
            if (!lastAccess){
              return
            }
            let idx = this.selectAllEventsOptions.findIndex(el=> el.id == lastAccess.event_id)
            if (idx > -1){
              this.selectedEvent = this.selectAllEventsOptions[idx]
              this.$nextTick(()=>[
                this.getEventObjectField()
              ])
            }
          }

        })
        .catch((err) => {
          console.error(err)
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
          this.selectedEvent = null;
          this.selectedReceiver = null;
          this.eventLoading = false;
        });
    },
    getServicesByEvent() {
      
      this.receiverLoading = true;
      this.$store
        .dispatch("getServicesByEvent", {
          transmissionID: this.$route.params.transmissionID,
          eventId: this.selectedEvent.id,
        })
        .then((response) => {

          if (this.transmissionServices){
            let temServOpts = []
            let permissions = structuredClone(response)

            this.transmissionServices.forEach(el => {
              let servPerm = permissions.find( p=> p.service.id == el.id)
              
              if (servPerm){
                el.can_receive = servPerm.can_receive
                el.can_send = servPerm.can_send
              } else {
                el.can_receive = false
                el.can_send = false
              }

              temServOpts.push(el)
            });

            this.selectAllServicesOptions = temServOpts

            this.receiverLoading = false;

            let r = this.selectAllServicesOptions.find(el => el.id == this.selectedService.id)
            if (r){
              this.selectedReceiver = r
            }

            return
          }
          this.selectReceiverOptions = response
          this.receiverLoading = false;
          
        })
        .catch((err) => {
          console.error(err);
          this.receiverLoading = false
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getServicesByTransmission() {
      this.$store
        .dispatch("getServicesByTransmissionGroup", {
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          // this.transmissionServices = response.items;
          let lastAccess = localStorage.getItem('lastAccessInfo')
          if (lastAccess){ 
            lastAccess = JSON.parse(lastAccess)[this.$route.params.transmissionID]
            if (!lastAccess){
              return
            }
            let idx = this.transmissionServices.findIndex(el=> el.id == lastAccess.service_id)
            if (idx > -1){
              this.selectedService = structuredClone(this.transmissionServices[idx])
              this.$nextTick(()=>{
                this.getEventByService()
              })
            }
          }
        })
        .catch((err) => {
          console.error(err)
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getEventsByTransmission() {
      this.$store
        .dispatch("getEventsByTransmissionGroup", {
          transmissionID: this.$route.params.transmissionID,
        })
        .then((resp) => {
        })
        .catch((err) => {
          console.error(err)
          makeToast({
            title: 'Error!',
            text: "Events couldn't be fetched...",
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getEventObjectField() {
      this.getServicesByEvent();
      
      this.$store
        .dispatch("getEventObjectField", {
          transmissionID: this.$route.params.transmissionID,
          eventID: this.selectedEvent.id,
        })
        .then((response) => {
          //this.eventObject = response;
        })
        .catch((err) => {
          console.error(err)
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    validateSubmit() {
      return this.$refs["test-script"].validate();
    },
    givePermissions(runAfter=true){
      this.modalLoading = true;
      if (runAfter){
        // this.runningTest = true;
      }
      
      let sender = structuredClone(this.selectedService)
      let event = structuredClone(this.selectedEvent)
      let receiver = structuredClone(this.selectedReceiver)

      let can_send = false
      let can_receive = false

      let exceptionSameSenderReceiver = false

      {// =========== CAN_SEND ===========
        let permSend = this.selectEventsOptions.find((el)=>{
          let e = el.event.id == event.id 
          let s = el.service.id == sender.id
          return e && s
        })
        if (permSend){
          //PERMISSION EXISTS
          if (permSend.can_send){
            can_send = true
            if (can_receive && can_receive){
              this.runTest()
            }
          } else {
            let payload = {
              transmissionID: this.$route.params.transmissionID,
              item: permSend
            }
            this.$store.dispatch('changeCanSend',payload).then((resp)=>{
              can_send = true
              if (can_receive && can_receive){
                this.runTest()
              }
              successToast({
                text: 'Added permission successfully'
              })
            })
          }
        } else {

          if (sender.id == receiver.id){
            exceptionSameSenderReceiver = true
          }
          
          //PERMISSION DOES NOT EXIST
          const payload = {
            transmissionID: this.$route.params.transmissionID,
            data:{
              event: event.id,
              service: sender.id
            }
          }
          this.$store.dispatch('addEventPermission', payload).then((resp)=>{
            

            can_send = true
            if (can_send && can_receive){
              this.runTest()
            }
            successToast({
              text: 'Added permission successfully'
            })
          })
          .catch((err)=>{
            console.error(err)
          })
        }
      }// ================================

      {// =========== CAN_RECEIVE ===========
        let permReceive = this.selectEventsOptions.find((el)=>{
          let e = el.event.id == event.id 
          let s = el.service.id == receiver.id
          return e && s
        })

        if (permReceive){
          //PERMISSION EXISTS
          if (permReceive.can_receive){
            can_receive = true
            if (can_receive && can_receive){
              this.runTest()
            }
          } else {
            let payload = {
              transmissionID: this.$route.params.transmissionID,
              item: permReceive
            }
            this.$store.dispatch('changeCanReceive',payload).then((resp)=>{
              can_receive = true
              if (can_receive && can_receive){
                this.runTest()
              }
              successToast({
                text: 'Added permission successfully'
              })
            })
          }
        } else {
          //PERMISSION DOES NOT EXIST
          if (exceptionSameSenderReceiver){
            can_receive = true;
            successToast({
              text: 'Added permission successfully'
            })
          } else {
            const payload = {
              transmissionID: this.$route.params.transmissionID,
              data:{
                event: event.id,
                service: receiver.id
              }
            }
            this.$store.dispatch('addEventPermission', payload).then((resp)=>{
              can_receive = true
              if (can_receive && can_receive){
                this.runTest()
              }
              successToast({
                text: 'Added permission successfully'
              })
            })
            .catch((err)=>{
              console.error(err)
            })
          }

        }
      }// ===================================
    
    },
    async handleSubmit() {
      this.validateSubmit().then((success) => {
        if (success) {
          
          if (!this.selectedEvent.can_send || !this.selectedReceiver.can_receive){
            if (this.autoAllowPermissions){
              //automatically concede permissions
              this.givePermissions();
              return
            } else {
              this.$refs['add-permissions-run-modal'].show()
              return
            }
          }
          
          this.runTest()
        }
      });
    },
    runTest(triggerParameters){    
      if (this.selectedReceiver){
        this.selectedEvent.can_send = true
        this.selectedReceiver.can_receive = true
      }
      this.modalLoading = false

      this.$nextTick(()=>{
        this.$refs['add-permissions-run-modal'].hide()
      })

      this.runningTest = true;

      let payload;

      if (triggerParameters){
        payload = {
          senderID: triggerParameters.senderId,
          eventID: triggerParameters.eventId,
          receiverID: triggerParameters.receiverId,
          transmissionID: this.$route.params.transmissionID,
          data: this.eventObject,
        }
        if (localStorage.getItem('allowRunShortcut') && localStorage.getItem('allowRunShortcut') == 'true'){
          
        } else{
          this.$refs['confirm-quickrun-modal'].show()
          return
        }
        makeToast({
          title:"Executing Test...",
          text:"Activated Quick Run shortcut",
          icon:"PlayIcon",
          variant:"favorite"
        })
      } else {
        payload = {
          senderID: parseInt(this.selectedService.id),
          eventID: parseInt(this.selectedEvent.id),
          receiverID: parseInt(this.selectedReceiver.id),
          transmissionID: this.$route.params.transmissionID,
          data: this.eventObject,
        };
      }

      const receiver = this.selectedReceiver;
      const initialDate = new Date()

      this.$store
        .dispatch("sendScriptTest", payload)
        .then((response) => {
          if (!response){
            throw('ERROR - Response is Undefined')
          }
          this.testResponse = response.response;
          this.testExecutionLog = response.log;
          this.runningTest = false;

          response.origin.receiver = receiver?.identifier;
          let run = {
            success: true,
            data: response
          }
          this.$refs["runs-list"].addRun(run);
        })
        .catch((err) => {
          let time = new Date() - initialDate;
          console.error(err)
          
          this.$refs["runs-list"].addError(err , time);
          this.runningTest = false;
          errorToast({text: "Attempted Run failed to execute"})
        });
    },
    toggleAutoAllowPermission(){
      if (!this.autoAllowPermissions){
        localStorage.removeItem('autoAllowPermissionsOnRun')
      } else {
        localStorage.setItem('autoAllowPermissionsOnRun', 'true')
      }
    },
    toggleAutoQuickRun(value){
      if (value == true){
        localStorage.setItem('allowRunShortcut', 'true')
      } else {
        localStorage.removeItem('allowRunShortcut')
      }

    },
    addTab(tab) {
      this.$emit("addTab", tab);
    },
    getSelectSize(){
      let el = this.$refs['v-select-base']
      let r = el.$el.clientWidth - 80 //to account for padding
      if (r < 100){
        r = 100
      }
      return r 
    }
  },
};
</script>

<style lang="scss" scoped>
.ellipsing-select-span{
  display: block;
  overflow: hidden;
  word-break: break-all; 
  white-space: nowrap;
  font-size: 13px;
}

.margin-right-minus{
  margin-right: -7px;
}

.v-select-dark {
  background-color: #141414 !important;
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.jv-dark {
  background: #161d31;
  white-space: nowrap;
  color: $white;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;
  box-shadow: 0 2px 7px rgb(0 0 0 / 15%) !important;
  border-color: transparent !important;
  position: relative !important;

  .jv-ellipsis {
    color: $white;
    background-color: #293148;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 5px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #a6e22e;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: $white;
    }
    &.jv-boolean {
      color: #ae81ff;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #ae81ff;
    }
    &.jv-number-float {
      color: #ae81ff;
    }
    &.jv-number-integer {
      color: #ae81ff;
    }
    &.jv-object {
      color: $white;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #e6db74;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #161d31;
        }
      }
    }
  }
}

.h3-separator {
  color: #ffffff2b !important;
  font-size: 40px;
}

.test-terminal {
  max-height: 80vh;

  .submit-button {
    text-align: right;
    z-index: 4;
    margin-top: calc(-1em - 20px) !important;
    margin-bottom: 10px;
  }
}

.counter-btn{
  height: 1.52em; 
  line-height: 1.52em; 
  width: 40px
}

.normalize-badge-icon {
  margin-left: 0 !important;
  height: 12px !important;
  width: 11px !important;
  font-size: 12px !important;
  stroke-width: 3 !important;
}
.low-opacity{
  opacity: 0.7;
}
.scale-down-tooltip{
  scale: 0.7;
  margin-left: -5px;
}
</style>