
export default [
  {
    title: "navigation.home",
    route: "home",
    icon: "HomeIcon",
    action: "read",
  },
  {
    title: "navigation.transmission",
    route: "transmission",
    icon: "PlusIcon",
    action: "manage",
  },
  {
    title: "navigation.profile",
    route: "profile",
    icon: "UserIcon",
    action: "read",
  },
  {
    title: "navigation.organization",
    route: "organization",
    icon: "GlobeIcon",
    action: "manage",
    resource: "organization",
  },
  {
    title: "navigation.servers",
    route: "servers",
    icon: "ServerIcon",
    action: "manage",
    resource: "server",
  },
  {
    title: "navigation.plans",
    route: "plans",
    icon: "CpuIcon",
    action: "manage",
    resource: "plan",
  },
  {
    title: "navigation.connection",
    route: "connections",
    icon: "KeyIcon",
    action: "manage",
  },
  {
    title: "navigation.integrator",
    route: "integrator-service",
    icon: "PackageIcon",
    action: "manage",
  },
];
