import moment from 'moment';
import Vue from 'vue';

export default class Run {
  constructor({ index, token, origin, success, level, message, event, sender, webhook, scheduler, json_return, created_at, time, transmission_id, script_id, receiver }) {
    this.index = index;
    this.token = token;
    this.origin = origin;
    this.success = success;
    this.level = level;
    this.message = message;
    this.event = event;
    this.sender = sender;
    this.webhook = webhook;
    this.scheduler = scheduler;
    this.receiver = receiver;
    try {
      json_return.info.body = JSON.parse(json_return.info.body);
      json_return.info.response = JSON.parse(json_return.info.response);
    } catch (e) { }
    this.json_return = json_return;
    this.created_at = created_at;
    this.time = time;
    this.transmission_id = transmission_id;
    this.script_id = script_id;

    this.middlewares = undefined;
  }

  sortMiddlewares(middlewares) {
    if(!Array.isArray(middlewares)) {
      return undefined;
    }

    return middlewares.sort((a, b) => {
      if(a.created_at > b.created_at) {
        return 1;
      }

      return -1;
    });
  }

  getMiddleware(category, identifier) {
    let mid_data = this.getCategory(category)[identifier];

    if(!Array.isArray(mid_data)) {
      mid_data = [ mid_data ];
    }

    return mid_data;
  }

  setMiddlewares(middlewares) {
    this.middlewares = middlewares;
  }
}