<template>
  <layout-vertical>

    <div>
      <TransmissionNav v-if="$route.meta.loadTransmissionGroup" />
      <IntegratorNav v-if="$route.meta.loadServiceHeader" />

      <router-view />
    </div>

    

    <div
      slot="breadcrumb"
      class="p-1"
    >
      <app-bread-crumb />
    </div>

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div
      slot="footer"
    >
      <app-footer/>
    </div>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from './Layout.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import AppBreadCrumb from '@/layouts/components/AppBreadcrumb.vue'
import Navbar from '@/layouts/components/Navbar.vue'
import TransmissionNav from '../components/Transmission/Nav.vue'
import IntegratorNav from '../components/Integrator/Nav.vue'
import AppFooter from '@/layouts/components/footer/AppFooter.vue'

export default {
  components: {
    // AppCustomizer,
    AppBreadCrumb,
    LayoutVertical,
    Navbar,
    TransmissionNav,
    AppFooter,
    IntegratorNav,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>


